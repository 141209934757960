import React from 'react';
import { Container, Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Company = () => {
  const { t } = useTranslation();

  return (
    <Container id="company">
      <Box textAlign="center" p={3}>
        <Typography variant="h4" gutterBottom>
          {t('company.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('company.description')}
        </Typography>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <Box p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
              <Typography variant="h6" gutterBottom>
                {t('company.mission.title')}
              </Typography>
              <Typography variant="body1">
                {t('company.mission.description')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
              <Typography variant="h6" gutterBottom>
                {t('company.vision.title')}
              </Typography>
              <Typography variant="body1">
                {t('company.vision.description')}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box mt={4} p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
          <Typography variant="h6" gutterBottom>
            {t('company.values.title')}
          </Typography>
          <Typography variant="body1" paragraph>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li>{t('company.values.trust')}</li>
              <li>{t('company.values.safety')}</li>
              <li>{t('company.values.convenience')}</li>
              <li>{t('company.values.community')}</li>
            </ul>
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            {t('company.history.title')}
          </Typography>
          <Typography variant="body1" paragraph>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li>{t('company.history.2023')}</li>
              <li>{t('company.history.2024')}</li>
              <li>{t('company.history.2025')}</li>
              <li>{t('company.history.2026')}</li>
            </ul>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Company;
