// import React from 'react';
// import { Box, Typography, Link, Divider } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// const Footer = () => {
//   const { t } = useTranslation();

//   return (
//     <Box
//       sx={{
//         bgcolor: '#4a4a4a', // Even lighter dark background color
//         color: '#f0f0f0', // Text color for dark mode
//         p: 6,
//         mt: 'auto',
//         textAlign: 'center',
//       }}
//       component="footer"
//     >
//       <Typography variant="subtitle1" component="p" sx={{ color: '#f0f0f0' }}>
//         {t('footer.subtitle')}
//       </Typography>
//       <Box my={2}>
//         <Link href="/service" variant="body2" sx={{ marginRight: 2, color: '#90caf9' }}>
//           {t('footer.service')}
//         </Link>
//         <Link href="/company" variant="body2" sx={{ marginRight: 2, color: '#90caf9' }}>
//           {t('footer.company')}
//         </Link>
//         <Link href="/support" variant="body2" sx={{ marginRight: 2, color: '#90caf9' }}>
//           {t('footer.support')}
//         </Link>
//         <Link href="/faq" variant="body2" sx={{ color: '#90caf9' }}>
//           {t('footer.faq')}
//         </Link>
//       </Box>
//       <Divider sx={{ my: 2, borderColor: '#757575' }} />
//       <Box>
//         <Typography variant="body2" sx={{ color: '#f0f0f0' }}>
//           &copy; {new Date().getFullYear()} Friendon.com. {t('footer.allRightsReserved')}
//         </Typography>
//         <Typography variant="body2" sx={{ color: '#f0f0f0' }}>
//           <Link href="https://supermarket.friendon.com/terms_list" variant="body2" sx={{ marginRight: 2, color: '#90caf9' }}>
//             {t('footer.terms')}
//           </Link>
//           <Link href="https://supermarket.friendon.com/privacy_list" variant="body2" sx={{ marginRight: 2, color: '#90caf9' }}>
//             {t('footer.privacy')}
//           </Link>
//           <Link href="/contact" variant="body2" sx={{ color: '#90caf9' }}>
//             {t('footer.contact')}
//           </Link>
//         </Typography>
//       </Box>
//       <Box mt={2}>
//         <Typography variant="body2" sx={{ color: '#f0f0f0' }}>
//           {t('footer.businessInfo')}
//         </Typography>
//         <Typography variant="body2" sx={{ color: '#f0f0f0' }}>
//           {t('footer.contactInfo')}
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

// export default Footer;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Link, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { incrementVisitorCount } from './VisitorCounter'; // 함수 불러오기

const Footer = () => {
  const { t } = useTranslation();
  const [visitorCounts, setVisitorCounts] = useState({ todayCount: 0, totalCount: 0 });

  useEffect(() => {
    const updateVisitorCounts = async () => {
      try {
        const counts = await incrementVisitorCount();
        setVisitorCounts(counts);
      } catch (error) {
        console.error('Error updating visitor counts:', error);
      }
    };

    updateVisitorCounts();
  }, []);

  return (
    <Box
      sx={{
        bgcolor: '#4a4a4a', // Even lighter dark background color
        color: '#f0f0f0', // Text color for dark mode
        p: 6,
        mt: 'auto',
        textAlign: 'center',
      }}
      component="footer"
    >
      <Typography variant="subtitle1" component="p" sx={{ color: '#f0f0f0' }}>
        {t('footer.subtitle')}
      </Typography>
      <Box my={2}>
        <Link href="/service" variant="body2" sx={{ marginRight: 2, color: '#90caf9' }}>
          {t('footer.service')}
        </Link>
        <Link href="/company" variant="body2" sx={{ marginRight: 2, color: '#90caf9' }}>
          {t('footer.company')}
        </Link>
        <Link href="/support" variant="body2" sx={{ marginRight: 2, color: '#90caf9' }}>
          {t('footer.support')}
        </Link>
        <Link href="/faq" variant="body2" sx={{ color: '#90caf9' }}>
          {t('footer.faq')}
        </Link>
      </Box>
      <Divider sx={{ my: 2, borderColor: '#757575' }} />
      <Box>
        <Typography variant="body2" sx={{ color: '#f0f0f0' }}>
          &copy; {new Date().getFullYear()} Friendon.com. {t('footer.allRightsReserved')}
        </Typography>
        <Typography variant="body2" sx={{ color: '#f0f0f0' }}>
          <Link href="https://supermarket.friendon.com/terms_list" variant="body2" sx={{ marginRight: 2, color: '#90caf9' }}>
            {t('footer.terms')}
          </Link>
          <Link href="https://supermarket.friendon.com/privacy_list" variant="body2" sx={{ marginRight: 2, color: '#90caf9' }}>
            {t('footer.privacy')}
          </Link>
          <Link href="/contact" variant="body2" sx={{ color: '#90caf9' }}>
            {t('footer.contact')}
          </Link>
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" sx={{ color: '#f0f0f0' }}>
          {t('footer.businessInfo')}
        </Typography>
        <Typography variant="body2" sx={{ color: '#f0f0f0' }}>
          {t('footer.contactInfo')}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" sx={{ color: '#f0f0f0' }}>
          {t('footer.todayVisitors')}: {visitorCounts.todayCount}
        </Typography>
        <Typography variant="body2" sx={{ color: '#f0f0f0' }}>
          {t('footer.totalVisitors')}: {visitorCounts.totalCount}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
