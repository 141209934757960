
// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, Box } from '@mui/material';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import LanguageIcon from '@mui/icons-material/Language';

// const NavBar = ({ activeMenu, onMenuClick }) => {
//     const { t, i18n } = useTranslation();
//     const [anchorEl, setAnchorEl] = React.useState(null);

//     const handleLanguageMenuClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleLanguageMenuClose = () => {
//         setAnchorEl(null);
//     };

//     const changeLanguage = (lng) => {
//         i18n.changeLanguage(lng);
//         handleLanguageMenuClose();
//     };

//   return (
//     <AppBar
//       position="fixed"
//       sx={{
//         backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white
//         boxShadow: 'none',
//         backdropFilter: 'blur(10px)', // Blur effect for a modern look
//         padding: '10px 0',
//       }}
//     >
//       <Toolbar sx={{ justifyContent: 'space-between' }}>
//         <Link to="/" style={{ textDecoration: 'none', flexGrow: 1 }} onClick={() => onMenuClick('home')}>
//           <Typography variant="h6" component="div" sx={{ color: activeMenu === 'home' ? 'primary.main' : '#333', fontWeight: 'bold' }}>
//             FRIENDON.COM
//           </Typography>
//         </Link>
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Button sx={{ color: activeMenu === 'home' ? 'primary.main' : '#333', fontWeight: 'bold' }} component={Link} to="/" onClick={() => onMenuClick('home')}>
//             {t('navbar.home')}
//           </Button>
//           <Button sx={{ color: activeMenu === 'company' ? 'primary.main' : '#333', fontWeight: 'bold' }} component={Link} to="/company" onClick={() => onMenuClick('company')}>
//             {t('navbar.company')}
//           </Button>
//           <Button sx={{ color: activeMenu === 'support' ? 'primary.main' : '#333', fontWeight: 'bold' }} component={Link} to="/support" onClick={() => onMenuClick('support')}>
//             {t('navbar.support')}
//           </Button>
//           <Button sx={{ color: activeMenu === 'faq' ? 'primary.main' : '#333', fontWeight: 'bold' }} component={Link} to="/faq" onClick={() => onMenuClick('faq')}>
//             {t('navbar.faq')}
//           </Button>
//           <Button sx={{ color: activeMenu === 'board' ? 'primary.main' : '#333', fontWeight: 'bold' }} component={Link} to="/board" onClick={() => onMenuClick('board')}>
//             {t('navbar.board')}
//           </Button>
//           <IconButton onClick={handleLanguageMenuClick} sx={{ color: '#333', ml: 2 }}>
//             <LanguageIcon />
//           </IconButton>
//           <Menu
//             anchorEl={anchorEl}
//             open={Boolean(anchorEl)}
//             onClose={handleLanguageMenuClose}
//           >
//             <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
//             <MenuItem onClick={() => changeLanguage('ko')}>한국어</MenuItem>
//             <MenuItem onClick={() => changeLanguage('ja')}>日本語</MenuItem>
//             <MenuItem onClick={() => changeLanguage('zh')}>中文</MenuItem>
//           </Menu>
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default NavBar;


import React from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';

const NavBar = React.forwardRef(({ activeMenu, onMenuClick, sx }, ref) => {
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleLanguageMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
        handleLanguageMenuClose();
    };

  return (
    <AppBar
      position="fixed"
      ref={ref}
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white
        boxShadow: 'none',
        backdropFilter: 'blur(10px)', // Blur effect for a modern look
        padding: '10px 0',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Link to="/" style={{ textDecoration: 'none', flexGrow: 1 }} onClick={() => onMenuClick('home')}>
          <Typography variant="h6" component="div" sx={{ color: activeMenu === 'home' ? 'primary.main' : '#333', fontWeight: 'bold' }}>
            FRIENDON.COM
          </Typography>
        </Link>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button sx={{ color: activeMenu === 'home' ? 'primary.main' : '#333', fontWeight: 'bold' }} component={Link} to="/" onClick={() => onMenuClick('home')}>
            {t('navbar.home')}
          </Button>
          <Button sx={{ color: activeMenu === 'company' ? 'primary.main' : '#333', fontWeight: 'bold' }} component={Link} to="/company" onClick={() => onMenuClick('company')}>
            {t('navbar.company')}
          </Button>
          <Button sx={{ color: activeMenu === 'service' ? 'primary.main' : '#333', fontWeight: 'bold' }} component={Link} to="/service" onClick={() => onMenuClick('service')}>
            {t('navbar.service')}
          </Button>
          <Button sx={{ color: activeMenu === 'support' ? 'primary.main' : '#333', fontWeight: 'bold' }} component={Link} to="/support" onClick={() => onMenuClick('support')}>
            {t('navbar.support')}
          </Button>
          <Button sx={{ color: activeMenu === 'faq' ? 'primary.main' : '#333', fontWeight: 'bold' }} component={Link} to="/faq" onClick={() => onMenuClick('faq')}>
            {t('navbar.faq')}
          </Button>
          <Button sx={{ color: activeMenu === 'board' ? 'primary.main' : '#333', fontWeight: 'bold' }} component={Link} to="/board" onClick={() => onMenuClick('board')}>
            {t('navbar.board')}
          </Button>
          <IconButton onClick={handleLanguageMenuClick} sx={{ color: '#333', ml: 2 }}>
            <LanguageIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleLanguageMenuClose}
          >
            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            <MenuItem onClick={() => changeLanguage('ko')}>한국어</MenuItem>
            <MenuItem onClick={() => changeLanguage('ja')}>日本語</MenuItem>
            <MenuItem onClick={() => changeLanguage('zh')}>中文</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
});

export default NavBar;
