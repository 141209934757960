
// import React, { useState } from 'react';
// import { BottomNavigation, BottomNavigationAction } from '@mui/material';
// import { styled, useTheme } from '@mui/material/styles';
// import HomeIcon from '@mui/icons-material/Home';
// import CompanyIcon from '@mui/icons-material/Business';
// import SupportIcon from '@mui/icons-material/SupportAgent';
// import FaqIcon from '@mui/icons-material/HelpOutline';
// import DashboardIcon from '@mui/icons-material/Dashboard'; // or
// import ForumIcon from '@mui/icons-material/Forum';

// import { scroller } from 'react-scroll';

// const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
//   color: '#757575', // Unselected color
//   '&.Mui-selected': {
//     color: '#1976d2', // Selected color
//     backgroundColor: '#e3f2fd', // Background color for selected state
//   },
// }));

// const MobileTabBar = () => {
//   const [activeTab, setActiveTab] = useState('home');

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//     scroller.scrollTo(newValue, {
//       duration: 800,
//       delay: 0,
//       smooth: 'easeInOutQuart',
//     });
//   };

//   return (
//     <BottomNavigation
//       value={activeTab}
//       onChange={handleTabChange}
//       showLabels
//       sx={{
//         width: '100%',
//         position: 'fixed',
//         bottom: 0,
//         zIndex: 1000,
//         backgroundColor: 'white',
//         borderTop: '1px solid #ddd',
//       }}
//     >
//       <StyledBottomNavigationAction
//         label="Home"
//         value="home"
//         icon={<HomeIcon />}
//       />
//       <StyledBottomNavigationAction
//         label="Company"
//         value="company"
//         icon={<CompanyIcon />}
//       />
//       <StyledBottomNavigationAction
//         label="Support"
//         value="support"
//         icon={<SupportIcon />}
//       />
//       <StyledBottomNavigationAction
//         label="FAQ"
//         value="faq"
//         icon={<FaqIcon />}
//       />
//       <StyledBottomNavigationAction
//         label="Board"
//         value="board"
//         icon={<DashboardIcon />}
//       />
//     </BottomNavigation>
//   );
// };

// export default MobileTabBar;


import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import CompanyIcon from '@mui/icons-material/Business';
import ServiceIcon from '@mui/icons-material/Store';
import SupportIcon from '@mui/icons-material/SupportAgent';
import FaqIcon from '@mui/icons-material/HelpOutline';
import DashboardIcon from '@mui/icons-material/Dashboard';

// const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
//   color: '#757575', // Unselected color
//   '&.Mui-selected': {
//     color: theme.palette.primary.main, // Selected color
//     backgroundColor: theme.palette.background.default, // Background color for selected state
//     '& .MuiBottomNavigationAction-label': {
//       fontSize: '0.775rem', // Explicitly set font size for selected state
//     },
//   },
//   '& .MuiBottomNavigationAction-label': {
//     fontSize: '0.775rem', // Explicitly set font size
//   },
//   '& .MuiSvgIcon-root': {
//     fontSize: '1.3rem', // Explicitly set icon size
//   },
// }));
const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
  color: '#757575', // Unselected color
  '&.Mui-selected': {
    color: theme.palette.primary.main, // Selected color
    backgroundColor: theme.palette.background.default, // Background color for selected state
    '& .MuiBottomNavigationAction-label': {
      fontSize: '0.775rem', // Explicitly set font size for selected state
    },
  },
  '& .MuiBottomNavigationAction-label': {
    fontSize: '0.775rem', // Explicitly set font size
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.3rem', // Explicitly set icon size
  },
  minWidth: 'auto', // Override default minWidth to allow for narrower buttons
  margin: '0 4px', // Adjust margin to reduce spacing between buttons
}));

const MobileTabBar = ({ activeMenu, onMenuClick }) => {
  const [activeTab, setActiveTab] = useState(activeMenu);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    onMenuClick(newValue);
  };

  return (
    <BottomNavigation
      value={activeTab}
      onChange={handleTabChange}
      showLabels
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1000,
        backgroundColor: 'white',
        borderTop: '1px solid #ddd',
      }}
    >
      <StyledBottomNavigationAction
        label="Home"
        value="home"
        icon={<HomeIcon />}
      />
      <StyledBottomNavigationAction
        label="Company"
        value="company"
        icon={<CompanyIcon />}
      />
      <StyledBottomNavigationAction
        label="Service"
        value="service"
        icon={<ServiceIcon />}
      />
      <StyledBottomNavigationAction
        label="Support"
        value="support"
        icon={<SupportIcon />}
      />
      <StyledBottomNavigationAction
        label="FAQ"
        value="faq"
        icon={<FaqIcon />}
      />
      <StyledBottomNavigationAction
        label="Board"
        value="board"
        icon={<DashboardIcon />}
      />
    </BottomNavigation>
  );
};

export default MobileTabBar;
