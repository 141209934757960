// import React,{useState} from 'react';
// import { Box, Typography, Button, useMediaQuery, useTheme, Alert, Snackbar } from '@mui/material';
// import PublicIcon from '@mui/icons-material/Public';
// import AppleIcon from '@mui/icons-material/Apple';
// import GoogleIcon from '@mui/icons-material/Google';
// import { useTranslation } from 'react-i18next';
// import backgroundImage from '../assets/images/homeback2.webp';

// const Home = () => {
//   const { t } = useTranslation();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');

//   const handleManualDownload = () => {
//     // Implement the manual download logic
//   };
//   const handleAppleButtonClick = () => {
//     const message = '아이폰 앱 서비스 준비중입니다. 정식 오픈일은 2024.08.15 입니다. 감사합니다.';
//     // window.open('https://appstore.link', '_blank');
//     setSnackbarMessage(message);
//     setSnackbarOpen(true);
//   }
//   const handleGoogleButtonClick = () => {
//     const message = '안드로이드 앱 서비스 준비중입니다. 정식 오픈일은 2024.08.15 입니다. 감사합니다.'
//     // window.open('https://googleplay.link', '_blank');
//     setSnackbarMessage(message);
//     setSnackbarOpen(true);
//   }
//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <Box
//       id="home"
//       textAlign="center"
//       p={3}
//       sx={{
//         position: 'relative',
//         minHeight: '100vh',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//         color: '#fff',
//         overflow: 'hidden',
//       }}
//     >
//       <Box
//         sx={{
//           position: 'absolute',
//           top: 0,
//           left: 0,
//           width: '100%',
//           height: '100%',
//           backgroundImage: `url(${backgroundImage})`,
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           zIndex: -1, // Ensure the background is behind the content
//         }}
//       />
//       <Box
//         sx={{
//           position: 'absolute',
//           top: 0,
//           left: 0,
//           width: '100%',
//           height: '100%',
//           backgroundColor: 'rgba(0, 0, 0, 0.2)', // Dark overlay
//           zIndex: -1, // Ensure the overlay is behind the content
//         }}
//       />
//       <Box
//         sx={{
//           width: '80%',
//           maxWidth: '600px',
//           backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark overlay
//           padding: isMobile ? '20px' : '40px',
//           borderRadius: '8px',
//           boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
//         }}
//       >
//         <Typography variant="h3" gutterBottom sx={{ fontSize: isMobile ? '2rem' : '3rem' }}>
//           {t('home.title')}
//         </Typography>
//         <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
//           <Button
//             variant="contained"
//             color="primary"
//             startIcon={<PublicIcon />}
//             onClick={() => window.open('https://supermarket.friendon.com', '_blank')}
//             sx={{ margin: '10px', padding: '10px 20px', fontSize: '1rem', backgroundColor: 'rgba(255, 255, 255, 0.2)', color: '#fff', width: isMobile ? '100%' : '200px' }}
//           >
//             Supermarket WebApp
//           </Button>
//         </Box>
//         <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
//           <Button
//             variant="contained"
//             color="primary"
//             startIcon={<AppleIcon />}
            
//             onClick={() => handleAppleButtonClick()}
//             sx={{ margin: '10px', padding: '10px 20px', fontSize: '1rem', backgroundColor: 'rgba(255, 255, 255, 0.2)', color: '#fff', width: isMobile ? '100%' : '200px' }}
//           >
//             App Store
//           </Button>
//           <Button
//             variant="contained"
//             color="primary"
//             startIcon={<GoogleIcon />}
            
//             onClick={() => handleGoogleButtonClick()}
//             sx={{ margin: '10px', padding: '10px 20px', fontSize: '1rem', backgroundColor: 'rgba(255, 255, 255, 0.2)', color: '#fff', width: isMobile ? '100%' : '200px' }}
//           >
//             Google Play
//           </Button>
//         </Box>
//         {/* <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
//           <Button
//             variant="outlined"
//             color="secondary"
//             onClick={handleManualDownload}
//             sx={{ margin: '10px', padding: '10px 20px', fontSize: '1rem', borderColor: '#fff', color: '#fff', backgroundColor: 'rgba(255, 255, 255, 0.2)', width: isMobile ? '100%' : '200px' }}
//           >
//             {t('home.userManual')}
//           </Button>
//           <Button
//             variant="outlined"
//             color="secondary"
//             onClick={handleManualDownload}
//             sx={{ margin: '10px', padding: '10px 20px', fontSize: '1rem', borderColor: '#fff', color: '#fff', backgroundColor: 'rgba(255, 255, 255, 0.2)', width: isMobile ? '100%' : '200px' }}
//           >
//             {t('home.bizManual')}
//           </Button>
//         </Box> */}
//         <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//       >
//         <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//       </Box>
//     </Box>
//   );
// };

// export default Home;


import React from 'react';
import { Box, Typography, Button, useMediaQuery, useTheme, Alert, Snackbar } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../assets/images/homeback2.webp';

const Home = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const navigate = useNavigate();

  const handleAppleButtonClick = () => {
    const message = t('iphoneService');
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleGoogleButtonClick = () => {
    const message = t('androidService');
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      id="home"
      textAlign="center"
      p={3}
      sx={{
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        overflow: 'hidden',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay for better contrast
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          width: '80%',
          maxWidth: '600px',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          padding: isMobile ? '20px' : '40px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        }}
      >
        <Typography variant="h3" gutterBottom sx={{ fontSize: isMobile ? '2rem' : '3rem', fontWeight: 'bold' }}>
          {t('home.title')}
        </Typography>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
          <Button
            variant="contained"
            startIcon={<PublicIcon />}
            onClick={() => window.open('https://supermarket.friendon.com', '_blank')}
            sx={{
              margin: '10px',
              padding: '10px 20px',
              fontSize: '1rem',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              color: '#fff',
              width: isMobile ? '100%' : '200px',
              transition: 'background-color 0.3s',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
              },
            }}
          >
            Supermarket WebApp
          </Button>
        </Box>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
          <Button
            variant="contained"
            startIcon={<AppleIcon />}
            onClick={handleAppleButtonClick}
            sx={{
              margin: '10px',
              padding: '10px 20px',
              fontSize: '1rem',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              color: '#fff',
              width: isMobile ? '100%' : '200px',
              transition: 'background-color 0.3s',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
              },
            }}
          >
            App Store
          </Button>
          <Button
            variant="contained"
            startIcon={<GoogleIcon />}
            onClick={handleGoogleButtonClick}
            sx={{
              margin: '10px',
              padding: '10px 20px',
              fontSize: '1rem',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              color: '#fff',
              width: isMobile ? '100%' : '200px',
              transition: 'background-color 0.3s',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
              },
            }}
          >
            Google Play
          </Button>
        </Box>
        {/* <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleManualDownload}
            sx={{ margin: '10px', padding: '10px 20px', fontSize: '1rem', borderColor: '#fff', color: '#fff', backgroundColor: 'rgba(255, 255, 255, 0.2)', width: isMobile ? '100%' : '200px' }}
          >
            {t('home.userManual')}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleManualDownload}
            sx={{ margin: '10px', padding: '10px 20px', fontSize: '1rem', borderColor: '#fff', color: '#fff', backgroundColor: 'rgba(255, 255, 255, 0.2)', width: isMobile ? '100%' : '200px' }}
          >
            {t('home.bizManual')}
          </Button>
        </Box> */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default Home;


