import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Button, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CuteImage from './assets/images/cute_404_image.webp'; // 이미지를 이 경로에 저장하세요

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        bgcolor: '#f0f0f0',
        padding: 4,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Box
        component="img"
        sx={{
          height: { xs: 233, md: 350 },
          width: { xs: 350, md: 500 },
          mb: 2,
        }}
        alt="Cute 404"
        src={CuteImage}
      />
      <Typography variant="h3" component="h1" gutterBottom>
        {t('notFound.title')}
      </Typography>
      <Typography variant="h6" component="p" gutterBottom>
        {t('notFound.description')}
      </Typography>
      <Button
        component={RouterLink}
        to="/"
        variant="contained"
        color="primary"
        size="large"
        sx={{ mt: 2 }}
      >
        {t('notFound.homeButton')}
      </Button>
    </Container>
  );
};

export default NotFound;
