
// // import React, { useState, useEffect, Suspense } from 'react';
// // import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
// // import { ThemeProvider, CssBaseline, Box, useMediaQuery } from '@mui/material';
// // import { I18nextProvider } from 'react-i18next';
// // import i18n from './i18n';
// // import theme from './theme';
// // import NavBar from './components/NavBar';
// // import Home from './Pages/Home';
// // import Company from './Pages/Company';
// // import Support from './Pages/Support';
// // import FAQ from './Pages/FAQ';
// // import Board from './Pages/Board';
// // import Footer from './components/Footer';
// // import ResponsiveContainer from './ResponsiveContainer';
// // import MobileTabBar from './MobileTabBar';
// // import NotFound from './NotFound';
// // import { scroller } from 'react-scroll';

// // const ScrollToTop = () => {
// //   const { pathname } = useLocation();
  
// //   useEffect(() => {
// //     window.scrollTo(0, 0);
// //   }, [pathname]);

// //   return null;
// // };

// // const App = () => {
// //   const [activeMenu, setActiveMenu] = useState('home');
// //   const isMobile = useMediaQuery('(max-width:600px)');
// //   const navBarHeight = 80; // Set NavBar height here

// //   const handleMenuClick = (section) => {
// //     setActiveMenu(section);
// //     scroller.scrollTo(section, {
// //       duration: 800,
// //       delay: 0,
// //       smooth: 'easeInOutQuart',
// //       offset: isMobile ? 0 : -navBarHeight, // Adjust this value to account for NavBar height
// //     });
// //   };

// //   useEffect(() => {
// //     const handleScroll = () => {
// //       const scrollPosition = window.scrollY + navBarHeight + 10; // Adjust this value according to your header height
// //       const sections = ['home', 'company', 'support', 'faq', 'board'];
// //       sections.forEach(section => {
// //         const element = document.getElementById(section);
// //         if (element) {
// //           if (element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition) {
// //             setActiveMenu(section);
// //           }
// //         }
// //       });
// //     };

// //     window.addEventListener('scroll', handleScroll);
// //     return () => {
// //       window.removeEventListener('scroll', handleScroll);
// //     };
// //   }, []);

// //   return (
// //     <ThemeProvider theme={theme}>
// //       <CssBaseline />
// //       <I18nextProvider i18n={i18n}>
// //         <Router>
// //           <ScrollToTop />

// //           {!isMobile && (
// //             <NavBar
// //               activeMenu={activeMenu}
// //               onMenuClick={handleMenuClick}
// //               sx={{ position: 'fixed', top: 0, width: '100%', height: `${navBarHeight}px`, zIndex: 1000 }}
// //             />
// //           )}
// //           <Box sx={{ overflowX: 'hidden', width: '100%', paddingTop: !isMobile ? `${navBarHeight}px` : '0' }}>
// //             <Suspense fallback={<div>Loading...</div>}>
// //               {isMobile && (
// //                 <MobileTabBar activeMenu={activeMenu} onMenuClick={handleMenuClick} />
// //               )}
// //               <Box className="main-content" sx={{ marginBottom: isMobile ? '56px' : '0' }}>
// //                 <Home />
// //                 <Company />
// //                 <Support />
// //                 <FAQ />
// //                 <Board />
// //                 <ResponsiveContainer>
// //                   <Routes>
// //                     <Route path="/" element={<Home />} />
// //                     <Route path="/company" element={<Company />} />
// //                     <Route path="/support" element={<Support />} />
// //                     <Route path="/faq" element={<FAQ />} />
// //                     <Route path="/board" element={<Board />} />
// //                     <Route path="*" element={<NotFound />} />
// //                   </Routes>
// //                   <Footer />
// //                 </ResponsiveContainer>
// //               </Box>
// //             </Suspense>
// //           </Box>
// //         </Router>
// //       </I18nextProvider>
// //     </ThemeProvider>
// //   );
// // };

// // export default App;


// import React, { useState, useEffect, useRef, Suspense } from 'react';
// import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
// import { ThemeProvider, CssBaseline, Box, useMediaQuery } from '@mui/material';
// import { I18nextProvider } from 'react-i18next';
// import i18n from './i18n';
// import theme from './theme';
// import NavBar from './components/NavBar';
// import Home from './Pages/Home';
// import Company from './Pages/Company';
// import Support from './Pages/Support';
// import FAQ from './Pages/FAQ';
// import Board from './Pages/Board';
// import Footer from './components/Footer';
// import ResponsiveContainer from './ResponsiveContainer';
// import MobileTabBar from './MobileTabBar';
// import NotFound from './NotFound';
// import { scroller } from 'react-scroll';
// import Service from './Pages/Service';
// import FloatingLanguage from './components/FloatingLanguage';
// import ReactGA from 'react-ga';

// const TRACKING_ID = 'G-JSC76WQBJ0'; // Google Analytics 추적 ID
// ReactGA.initialize(TRACKING_ID);

// const ScrollToTop = () => {
//   const { pathname } = useLocation();
  
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// };

// const App = () => {
//   const location = useLocation();
//   const [activeMenu, setActiveMenu] = useState('home');
//   const isMobile = useMediaQuery('(max-width:600px)');
//   const navBarRef = useRef(null); // Ref to get NavBar height

//   const handleMenuClick = (section) => {
//     setActiveMenu(section);
//     const navBarHeight = navBarRef.current ? navBarRef.current.offsetHeight : 0;
//     scroller.scrollTo(section, {
//       duration: 800,
//       delay: 0,
//       smooth: 'easeInOutQuart',
//       offset: -navBarHeight, // Use dynamic NavBar height
//     });
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       const navBarHeight = navBarRef.current ? navBarRef.current.offsetHeight : 0;
//       const scrollPosition = window.scrollY + navBarHeight + 10; // Adjust this value according to your header height
//       const sections = ['home', 'company', 'service', 'support', 'faq', 'board'];
//       sections.forEach(section => {
//         const element = document.getElementById(section);
//         if (element) {
//           if (element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition) {
//             setActiveMenu(section);
//           }
//         }
//       });
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);


//   return (
//     <Router>
//           <ScrollToTop />
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <I18nextProvider i18n={i18n}>
        
//           {!isMobile && (
//             <NavBar
//               ref={navBarRef}
//               activeMenu={activeMenu}
//               onMenuClick={handleMenuClick}
//               sx={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}
//             />
//           )}
//           <FloatingLanguage />
//           <Box sx={{ overflowX: 'hidden', width: '100%', paddingTop: !isMobile ? `${navBarRef.current ? navBarRef.current.offsetHeight : 0}px` : '0' }}>
//             <Suspense fallback={<div>Loading...</div>}>
//               {isMobile && (
//                 <MobileTabBar activeMenu={activeMenu} onMenuClick={handleMenuClick} />
//               )}
//               <Box className="main-content" sx={{ marginBottom: isMobile ? '56px' : '0' }}>
//                 <Home />
//                 <Company />
//                 <Service />
//                 <Support />
//                 <FAQ />
//                 <Board />
//                 <ResponsiveContainer>
//                   <Routes location={location} key={location.pathname}>
//                     <Route path="/" element={<Home />} />
//                     <Route path="/company" element={<Company />} />
//                     <Route path="/service" element={<Service />} />
//                     <Route path="/support" element={<Support />} />
//                     <Route path="/faq" element={<FAQ />} />
//                     <Route path="/board" element={<Board />} />
//                     <Route path="*" element={<NotFound />} />
//                   </Routes>
//                   <Footer />
//                 </ResponsiveContainer>
//               </Box>
//             </Suspense>
//           </Box>
        
//       </I18nextProvider>
//     </ThemeProvider>
//     </Router>
//   );
// };

// export default App;



import React, { useState, useEffect, useRef, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Box, useMediaQuery } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import theme from './theme';
import NavBar from './components/NavBar';
import Home from './Pages/Home';
import Company from './Pages/Company';
import Support from './Pages/Support';
import FAQ from './Pages/FAQ';
import Board from './Pages/Board';
import Footer from './components/Footer';
import ResponsiveContainer from './ResponsiveContainer';
import MobileTabBar from './MobileTabBar';
import NotFound from './NotFound';
import { scroller } from 'react-scroll';
import Service from './Pages/Service';
import FloatingLanguage from './components/FloatingLanguage';
import ReactGA from 'react-ga';

const TRACKING_ID = 'G-JSC76WQBJ0'; // Google Analytics 추적 ID
ReactGA.initialize(TRACKING_ID);

const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(pathname); // 페이지뷰 추적
  }, [pathname]);

  return null;
};

const AppContent = () => {
  const [activeMenu, setActiveMenu] = useState('home');
  const isMobile = useMediaQuery('(max-width:600px)');
  const navBarRef = useRef(null); // Ref to get NavBar height

  const handleMenuClick = (section) => {
    setActiveMenu(section);
    const navBarHeight = navBarRef.current ? navBarRef.current.offsetHeight : 0;
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -navBarHeight, // Use dynamic NavBar height
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const navBarHeight = navBarRef.current ? navBarRef.current.offsetHeight : 0;
      const scrollPosition = window.scrollY + navBarHeight + 10; // Adjust this value according to your header height
      const sections = ['home', 'company', 'service', 'support', 'faq', 'board'];
      sections.forEach(section => {
        const element = document.getElementById(section);
        if (element) {
          if (element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition) {
            setActiveMenu(section);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box sx={{ overflowX: 'hidden', width: '100%', paddingTop: !isMobile ? `${navBarRef.current ? navBarRef.current.offsetHeight : 0}px` : '0' }}>
      <Suspense fallback={<div>Loading...</div>}>
        {!isMobile && (
          <>
            <NavBar ref={navBarRef} activeMenu={activeMenu} onMenuClick={handleMenuClick} sx={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }} />
            <FloatingLanguage />
          </>
        )}
        {isMobile && <MobileTabBar activeMenu={activeMenu} onMenuClick={handleMenuClick} />}
        <Box className="main-content" sx={{ marginBottom: isMobile ? '56px' : '0' }}>
          <ResponsiveContainer>
            <Home />
            <Company />
            <Service />
            <Support />
            <FAQ />
            <Board />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/company" element={<Company />} />
              <Route path="/service" element={<Service />} />
              <Route path="/support" element={<Support />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/board" element={<Board />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </ResponsiveContainer>
        </Box>
      </Suspense>
    </Box>
  );
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <I18nextProvider i18n={i18n}>
          <AppContent />
        </I18nextProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
