
// import React from 'react';
// import { Container, Box, Typography, Grid } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// const Service = () => {
//   const { t } = useTranslation();

//   return (
//     <Container id="service">
//       <Box textAlign="center" p={3}>
//         <Typography variant="h4" gutterBottom>
//           {t('service.title')}
//         </Typography>
//         <Typography variant="h6" gutterBottom>
//           {t('service.subtitle')}
//         </Typography>
//         <Typography variant="body1" paragraph>
//           {t('service.description')}
//         </Typography>

//         <Grid container spacing={4} sx={{ mt: 4 }}>
//           <Grid item xs={12} md={6}>
//             <Box p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
//               <Typography variant="h6" gutterBottom>
//                 {t('service.neighborhood.title')}
//               </Typography>
//               <Typography variant="body1">
//                 {t('service.neighborhood.description')}
//               </Typography>
//             </Box>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Box p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
//               <Typography variant="h6" gutterBottom>
//                 {t('service.localStores.title')}
//               </Typography>
//               <Typography variant="body1">
//                 {t('service.localStores.description')}
//               </Typography>
//             </Box>
//           </Grid>
//         </Grid>

//         <Box mt={4} p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
//           <Typography variant="h6" gutterBottom>
//             {t('service.weatherDiscounts.title')}
//           </Typography>
//           <Typography variant="body1" paragraph>
//             {t('service.weatherDiscounts.description')}
//           </Typography>
//         </Box>

//         <Box mt={4}>
//           <Typography variant="h6" gutterBottom>
//             {t('service.latestTech.features.title')}
//           </Typography>

//           <Grid container spacing={4} sx={{ mt: 2 }}>
//             <Grid item xs={12} md={6}>
//               <Box p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   {t('service.latestTech.features.relatedProducts.title')}
//                 </Typography>
//                 <Typography variant="body2" paragraph>
//                   {t('service.latestTech.features.relatedProducts.description')}
//                 </Typography>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Box p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   {t('service.latestTech.features.safeChat.title')}
//                 </Typography>
//                 <Typography variant="body2" paragraph>
//                   {t('service.latestTech.features.safeChat.description')}
//                 </Typography>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Box p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   {t('service.latestTech.features.llmAutomation.title')}
//                 </Typography>
//                 <Typography variant="body2" paragraph>
//                   {t('service.latestTech.features.llmAutomation.description')}
//                 </Typography>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Box p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   {t('service.latestTech.features.cloudComputing.title')}
//                 </Typography>
//                 <Typography variant="body2" paragraph>
//                   {t('service.latestTech.features.cloudComputing.description')}
//                 </Typography>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Box p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   {t('service.latestTech.features.securityEnhancements.title')}
//                 </Typography>
//                 <Typography variant="body2" paragraph>
//                   {t('service.latestTech.features.securityEnhancements.description')}
//                 </Typography>
//               </Box>
//             </Grid>
//           </Grid>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default Service;


import React from 'react';
import { Container, Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Service = () => {
  const { t } = useTranslation();

  return (
    <Container id="service">
      <Box textAlign="center" p={3}>
        <Typography variant="h4" gutterBottom>
          {t('service.title')}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {t('service.subtitle')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('service.description')}
        </Typography>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <Box
              p={3}
              sx={{
                backgroundColor: '#f9f9f9',
                borderRadius: '8px',
                boxShadow: 2,
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <Typography variant="h6" gutterBottom>
                {t('service.neighborhood.title')}
              </Typography>
              <Typography variant="body1">
                {t('service.neighborhood.description')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              p={3}
              sx={{
                backgroundColor: '#f9f9f9',
                borderRadius: '8px',
                boxShadow: 2,
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <Typography variant="h6" gutterBottom>
                {t('service.localStores.title')}
              </Typography>
              <Typography variant="body1">
                {t('service.localStores.description')}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box mt={4} p={3} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: 2 }}>
          <Typography variant="h6" gutterBottom>
            {t('service.weatherDiscounts.title')}
          </Typography>
          <Typography variant="body1" paragraph>
            {t('service.weatherDiscounts.description')}
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            {t('service.latestTech.features.title')}
          </Typography>

          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <Box
                p={3}
                sx={{
                  backgroundColor: '#f9f9f9',
                  borderRadius: '8px',
                  boxShadow: 2,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  {t('service.latestTech.features.relatedProducts.title')}
                </Typography>
                <Typography variant="body2" paragraph>
                  {t('service.latestTech.features.relatedProducts.description')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                p={3}
                sx={{
                  backgroundColor: '#f9f9f9',
                  borderRadius: '8px',
                  boxShadow: 2,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  {t('service.latestTech.features.safeChat.title')}
                </Typography>
                <Typography variant="body2" paragraph>
                  {t('service.latestTech.features.safeChat.description')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                p={3}
                sx={{
                  backgroundColor: '#f9f9f9',
                  borderRadius: '8px',
                  boxShadow: 2,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  {t('service.latestTech.features.llmAutomation.title')}
                </Typography>
                <Typography variant="body2" paragraph>
                  {t('service.latestTech.features.llmAutomation.description')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                p={3}
                sx={{
                  backgroundColor: '#f9f9f9',
                  borderRadius: '8px',
                  boxShadow: 2,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  {t('service.latestTech.features.cloudComputing.title')}
                </Typography>
                <Typography variant="body2" paragraph>
                  {t('service.latestTech.features.cloudComputing.description')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                p={3}
                sx={{
                  backgroundColor: '#f9f9f9',
                  borderRadius: '8px',
                  boxShadow: 2,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  {t('service.latestTech.features.securityEnhancements.title')}
                </Typography>
                <Typography variant="body2" paragraph>
                  {t('service.latestTech.features.securityEnhancements.description')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Service;
