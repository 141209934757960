// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2', // Dark blue
      },
      secondary: {
        main: '#ffc107', // Amber
      },
      background: {
        default: '#f5f5f5', // Light grey
      },
      text: {
        primary: '#333', // Dark grey
        secondary: '#fff', // White
      },
    },
    typography: {
      fontSize: 18,
      h3: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
      },
      h4: {
        fontSize: '2.125rem',
      },
    },
    spacing: 4,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '12px 24px',
            fontSize: '1rem',
            margin: '0 10px',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            marginBottom: '16px',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            paddingBottom: '16px',
          },
        },
      },
    },
  });
  export default theme;