// import React, { useState, useEffect } from 'react';
// import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, Pagination } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { useTranslation } from 'react-i18next';

// import i18n from '../i18n'; // Import the i18n instance

// const FAQ = () => {
//   const { t, i18n } = useTranslation();
//   const [searchTerm, setSearchTerm] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const faqsPerPage = 5;

//   const currentLanguage = i18n.language; // 현재 언어 가져오기

//   const faqs = Object.keys(t('faq.questions'))
//     .filter(key => key <= 10) // Filter out items beyond 10 (in case there are more)
//     .map(key => ({
//       id: key,
//       question: t(`faq.questions.${key}.question`),
//       answer: t(`faq.questions.${key}.answer`),
//     }));

//   const filteredFaqs = faqs.filter(faq =>
//     faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const displayedFaqs = filteredFaqs.slice(
//     (currentPage - 1) * faqsPerPage,
//     currentPage * faqsPerPage
//   );

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   return (
//     <Box p={3} id="faq">
//       <Typography variant="h4" gutterBottom>{t('faq.title')}</Typography>
//       <TextField
//         variant="outlined"
//         fullWidth
//         placeholder={t('faq.placeholder')}
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         sx={{ marginBottom: '20px' }}
//       />
//       {displayedFaqs.map((faq) => (
//         <Accordion key={faq.id}>
//           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//             <Typography variant="h6">{faq.question}</Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography variant="body1">{faq.answer}</Typography>
//           </AccordionDetails>
//         </Accordion>
//       ))}
//       {filteredFaqs.length === 0 && (
//         <Typography variant="body1" textAlign="center">{t('faq.noResults')}</Typography>
//       )}
//       <Box display="flex" justifyContent="center" mt={4}>
//         <Pagination
//           count={Math.ceil(filteredFaqs.length / faqsPerPage)}
//           page={currentPage}
//           onChange={handlePageChange}
//           color="primary"
//         />
//       </Box>
//     </Box>
//   );
// };

// export default FAQ;


import React, { useState, useEffect } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, Pagination } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t, i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const faqsPerPage = 5;

  const faqs = Object.keys(t('faq.questions'))
    .map(key => ({
      id: parseInt(key, 10),
      question: t(`faq.questions.${key}.question`),
      answer: t(`faq.questions.${key}.answer`),
    }))
    .filter(faq => !isNaN(faq.id))
    .sort((a, b) => a.id - b.id)
    .slice(0, 10); // Ensure only the first 10 FAQs are considered

  const filteredFaqs = faqs.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const displayedFaqs = filteredFaqs.slice(
    (currentPage - 1) * faqsPerPage,
    currentPage * faqsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box p={3} id="faq">
      <Typography variant="h4" textAlign="center"gutterBottom>{t('faq.title')}</Typography>
      <TextField
        variant="outlined"
        fullWidth
        placeholder={t('faq.placeholder')}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ marginBottom: '20px' }}
      />
      {displayedFaqs.map((faq) => (
        <Accordion key={faq.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      {filteredFaqs.length === 0 && (
        <Typography variant="body1" textAlign="center">{t('faq.noResults')}</Typography>
      )}
      <Box display="flex" justifyContent="center" mt={4}>
        <Pagination
          count={Math.ceil(filteredFaqs.length / faqsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default FAQ;
