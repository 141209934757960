
// // import React, { useState, useEffect } from 'react';
// // import { Box, TextField, Button, Typography, Grid, Container, Alert, Paper, IconButton } from '@mui/material';
// // import SearchIcon from '@mui/icons-material/Search';
// // import AddIcon from '@mui/icons-material/Add';
// // import CloseIcon from '@mui/icons-material/Close';
// // import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
// // import { db } from '../firebaseConfig';
// // import { useTranslation } from 'react-i18next';

// // const Support = () => {
// //   const { t } = useTranslation();
// //   const [searchQuery, setSearchQuery] = useState('');
// //   const [searchResults, setSearchResults] = useState([]);
// //   const [searchPerformed, setSearchPerformed] = useState(false);
// //   const [error, setError] = useState(null);
// //   const [newArticleTitle, setNewArticleTitle] = useState('');
// //   const [newArticleContent, setNewArticleContent] = useState('');
// //   const [newArticleKeywords, setNewArticleKeywords] = useState('');
// //   const [submissionError, setSubmissionError] = useState(null);
// //   const [submissionSuccess, setSubmissionSuccess] = useState(null);
// //   const [showNewArticleForm, setShowNewArticleForm] = useState(false);
// //   const keywords = [t('support.keywords.delivery'), t('support.keywords.payment'), t('support.keywords.refund'), t('support.keywords.signup')];

// //   const handleKeywordClick = (keyword) => {
// //     setSearchQuery(keyword);
// //     handleSearch(keyword);
// //   };

// //   const handleSearch = async (queryText = searchQuery) => {
// //     try {
// //       const q = query(collection(db, 'supports'), where('keywords', 'array-contains', queryText));
// //       const querySnapshot = await getDocs(q);
// //       const results = [];
// //       querySnapshot.forEach((doc) => {
// //         results.push({ id: doc.id, ...doc.data() });
// //       });
// //       setSearchResults(results);
// //       setSearchPerformed(true);
// //       setError(null);
// //     } catch (err) {
// //       setError(t('support.error.search'));
// //       console.error('Error searching documents: ', err);
// //     }
// //   };

// //   const handleNewArticleSubmit = async () => {
// //     if (!newArticleTitle || !newArticleContent || !newArticleKeywords) {
// //       setSubmissionError(t('support.error.emptyFields'));
// //       return;
// //     }

// //     const newArticle = {
// //       title: newArticleTitle,
// //       content: newArticleContent,
// //       keywords: newArticleKeywords.split(',').map(keyword => keyword.trim()),
// //       createdAt: new Date(),
// //     };

// //     try {
// //       await addDoc(collection(db, 'supports'), newArticle);
// //       setNewArticleTitle('');
// //       setNewArticleContent('');
// //       setNewArticleKeywords('');
// //       setSubmissionError(null);
// //       setSubmissionSuccess(t('support.success.articleAdded'));
// //       setShowNewArticleForm(false);
// //     } catch (err) {
// //       setSubmissionError(t('support.error.addingArticle'));
// //       console.error('Error adding document: ', err);
// //     }
// //   };

// //   return (
// //     <Container  id="support">
// //       <Box textAlign="center" p={3}>
// //         <Typography variant="h4" gutterBottom>{t('support.title')}</Typography>
// //         <Box mb={3} display="flex" justifyContent="center">
// //           <TextField
// //             variant="outlined"
// //             value={searchQuery}
// //             onChange={(e) => setSearchQuery(e.target.value)}
// //             placeholder={t('support.placeholder')}
// //             sx={{ width: '70%' }}
// //             InputProps={{
// //               endAdornment: (
// //                 <IconButton onClick={() => handleSearch(searchQuery)}>
// //                   <SearchIcon />
// //                 </IconButton>
// //               ),
// //             }}
// //           />
// //         </Box>
// //         <Grid container spacing={2} justifyContent="center">
// //           {keywords.map((keyword) => (
// //             <Grid item key={keyword}>
// //               <Button
// //                 variant="outlined"
// //                 onClick={() => handleKeywordClick(keyword)}
// //                 sx={{ margin: '5px' }}
// //               >
// //                 {keyword}
// //               </Button>
// //             </Grid>
// //           ))}
// //         </Grid>
// //         {searchPerformed && (
// //           <Box mt={4}>
// //             {searchResults.length > 0 ? (
// //               searchResults.map((result, index) => (
// //                 <Paper key={index} my={2} p={2} sx={{ borderRadius: '8px', boxShadow: 3 }}>
// //                   <Typography variant="h6">{result.title}</Typography>
// //                   <Typography variant="body1">{result.content}</Typography>
// //                 </Paper>
// //               ))
// //             ) : (
// //               <Alert severity="info">{t('support.noResults')}</Alert>
// //             )}
// //           </Box>
// //         )}
// //         {error && (
// //           <Box mt={4}>
// //             <Alert severity="error">{error}</Alert>
// //           </Box>
// //         )}
// //       </Box>
// //       <Box mt={5} p={3} sx={{ borderTop: '1px solid #ccc' }}>
// //         <Box display="flex" justifyContent="space-between" alignItems="center">
// //           <Typography variant="h5" gutterBottom>{t('support.newArticle')}</Typography>
// //           <IconButton onClick={() => setShowNewArticleForm(!showNewArticleForm)}>
// //             {showNewArticleForm ? <CloseIcon /> : <AddIcon />}
// //           </IconButton>
// //         </Box>
// //         {showNewArticleForm && (
// //           <Box>
// //             <TextField
// //               variant="outlined"
// //               fullWidth
// //               margin="normal"
// //               label={t('support.form.title')}
// //               value={newArticleTitle}
// //               onChange={(e) => setNewArticleTitle(e.target.value)}
// //             />
// //             <TextField
// //               variant="outlined"
// //               fullWidth
// //               margin="normal"
// //               label={t('support.form.content')}
// //               multiline
// //               minRows={4}
// //               value={newArticleContent}
// //               onChange={(e) => setNewArticleContent(e.target.value)}
// //             />
// //             <TextField
// //               variant="outlined"
// //               fullWidth
// //               margin="normal"
// //               label={t('support.form.keywords')}
// //               value={newArticleKeywords}
// //               onChange={(e) => setNewArticleKeywords(e.target.value)}
// //             />
// //             <Button variant="contained" color="primary" onClick={handleNewArticleSubmit} sx={{ mt: 2 }}>
// //               {t('support.form.submit')}
// //             </Button>
// //             {submissionError && (
// //               <Box mt={2}>
// //                 <Alert severity="error">{submissionError}</Alert>
// //               </Box>
// //             )}
// //             {submissionSuccess && (
// //               <Box mt={2}>
// //                 <Alert severity="success">{submissionSuccess}</Alert>
// //               </Box>
// //             )}
// //           </Box>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default Support;

import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Grid, Container, Alert, Paper, IconButton, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const Support = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newArticleTitle, setNewArticleTitle] = useState('');
  const [newArticleContent, setNewArticleContent] = useState('');
  const [newArticleKeywords, setNewArticleKeywords] = useState('');
  const [submissionError, setSubmissionError] = useState(null);
  const [submissionSuccess, setSubmissionSuccess] = useState(null);
  const [showNewArticleForm, setShowNewArticleForm] = useState(false);
  const keywords = [t('support.keywords.delivery'), t('support.keywords.payment'), t('support.keywords.refund'), t('support.keywords.signup')];

  const handleKeywordClick = (keyword) => {
    setSearchQuery(keyword);
    handleSearch(keyword);
  };

  const handleSearch = async (queryText = searchQuery) => {
    setLoading(true);
    try {
      const q = query(collection(db, 'supports'), where('keywords', 'array-contains', queryText));
      const querySnapshot = await getDocs(q);
      const results = [];
      querySnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() });
      });
      setSearchResults(results);
      setSearchPerformed(true);
      setError(null);
    } catch (err) {
      setError(t('support.error.search'));
      console.error('Error searching documents: ', err);
    } finally {
      setLoading(false);
    }
  };

  const handleNewArticleSubmit = async () => {
    if (!newArticleTitle || !newArticleContent || !newArticleKeywords) {
      setSubmissionError(t('support.error.emptyFields'));
      return;
    }

    const newArticle = {
      title: newArticleTitle,
      content: newArticleContent,
      keywords: newArticleKeywords.split(',').map(keyword => keyword.trim()),
      createdAt: new Date(),
    };

    try {
      await addDoc(collection(db, 'supports'), newArticle);
      setNewArticleTitle('');
      setNewArticleContent('');
      setNewArticleKeywords('');
      setSubmissionError(null);
      setSubmissionSuccess(t('support.success.articleAdded'));
      setShowNewArticleForm(false);
    } catch (err) {
      setSubmissionError(t('support.error.addingArticle'));
      console.error('Error adding document: ', err);
    }
  };

  return (
    <Container id="support">
      <Box textAlign="center" p={3} component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Typography variant="h4" gutterBottom>{t('support.title')}</Typography>
        <Box mb={3} display="flex" justifyContent="center">
          <TextField
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t('support.placeholder')}
            sx={{
              width: { xs: '100%', sm: '70%' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ddd',
                },
                '&:hover fieldset': {
                  borderColor: '#aaa',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#000',
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => handleSearch(searchQuery)}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </Box>
        <Grid container spacing={2} justifyContent="center">
          {keywords.map((keyword) => (
            <Grid item key={keyword}>
              <Button
                variant="outlined"
                onClick={() => handleKeywordClick(keyword)}
                sx={{
                  margin: '5px',
                  borderColor: '#ddd',
                  '&:hover': {
                    borderColor: '#aaa',
                    backgroundColor: '#f9f9f9',
                  },
                }}
              >
                {keyword}
              </Button>
            </Grid>
          ))}
        </Grid>
        {loading ? (
          <Box mt={4} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : searchPerformed && (
          <Box mt={4}>
            {searchResults.length > 0 ? (
              searchResults.map((result, index) => (
                <Paper key={index} my={2} p={2} sx={{ borderRadius: '8px', boxShadow: 3 }}>
                  <Typography variant="h6" gutterBottom>{result.title}</Typography>
                  <Typography variant="body1" gutterBottom>{result.content}</Typography>
                </Paper>
              ))
            ) : (
              <Alert severity="info">{t('support.noResults')}</Alert>
            )}
          </Box>
        )}
        {error && (
          <Box mt={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
      </Box>
      {/* <Box mt={5} p={3} sx={{ borderTop: '1px solid #ccc' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" gutterBottom>{t('support.newArticle')}</Typography>
          <IconButton onClick={() => setShowNewArticleForm(!showNewArticleForm)}>
            {showNewArticleForm ? <CloseIcon /> : <AddIcon />}
          </IconButton>
        </Box>
        {showNewArticleForm && (
          <Box
            component={motion.div}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              label={t('support.form.title')}
              value={newArticleTitle}
              onChange={(e) => setNewArticleTitle(e.target.value)}
            />
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              label={t('support.form.content')}
              multiline
              minRows={4}
              value={newArticleContent}
              onChange={(e) => setNewArticleContent(e.target.value)}
            />
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              label={t('support.form.keywords')}
              value={newArticleKeywords}
              onChange={(e) => setNewArticleKeywords(e.target.value)}
              placeholder={t('support.form.keywordsPlaceholder')}
              helperText={t('support.form.keywordsHelper')}
            />
            <Button variant="contained" color="primary" onClick={handleNewArticleSubmit} sx={{ mt: 2 }}>
              {t('support.form.submit')}
            </Button>
            {submissionError && (
              <Box mt={2}>
                <Alert severity="error">{submissionError}</Alert>
              </Box>
            )}
            {submissionSuccess && (
              <Box mt={2}>
                <Alert severity="success">{submissionSuccess}</Alert>
              </Box>
            )}
          </Box>
        )}
      </Box> */}
    </Container>
  );
};

export default Support;
