import React from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const FloatingLanguage = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLanguageMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    handleLanguageMenuClose();
  };

  if (!isMobile) {
    return null; // Only show on mobile
  }

  return (
    <div style={{ position: 'fixed', bottom: 80, right: 20, zIndex: 1000 }}>
      <Tooltip title="Change Language">
        <IconButton
          onClick={handleLanguageMenuClick}
          sx={{
            backgroundColor: 'white',
            boxShadow: 3,
            '&:hover': {
              backgroundColor: 'lightgray',
            },
          }}
        >
          <LanguageIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleLanguageMenuClose}
      >
        <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
        <MenuItem onClick={() => changeLanguage('ko')}>한국어</MenuItem>
        <MenuItem onClick={() => changeLanguage('ja')}>日本語</MenuItem>
        <MenuItem onClick={() => changeLanguage('zh')}>中文</MenuItem>
      </Menu>
    </div>
  );
};

export default FloatingLanguage;
