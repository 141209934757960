import { db, doc, getDoc, setDoc, updateDoc, serverTimestamp, collection, query, where, getDocs } from '../firebaseConfig';
import axios from 'axios';

// IP 주소 가져오는 함수
const getIpAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Error getting IP address:', error);
    return null;
  }
};

// 방문자 수 증가 함수
export const incrementVisitorCount = async () => {
  const today = new Date().toISOString().slice(0, 10);
  const visitorDocRef = doc(db, 'visitorCounts', 'count');
  const ipAddress = await getIpAddress();

  if (!ipAddress) {
    console.error('Failed to get IP address');
    return { todayCount: 0, totalCount: 0 };
  }

  try {
    // 오늘 날짜와 IP 주소로 쿼리
    const ipQuery = query(collection(db, 'visitorIPs'), where('ip', '==', ipAddress), where('date', '==', today));
    const ipQuerySnapshot = await getDocs(ipQuery);

    // 중복되지 않은 경우에만 카운트 증가
    if (ipQuerySnapshot.empty) {
      await setDoc(doc(collection(db, 'visitorIPs')), {
        ip: ipAddress,
        date: today,
        timestamp: serverTimestamp()
      });

      const visitorDoc = await getDoc(visitorDocRef);
      let newTodayCount = 1;
      let newTotalCount = 1;

      if (visitorDoc.exists()) {
        const data = visitorDoc.data();
        const lastUpdated = data.lastUpdated ? data.lastUpdated.toDate().toISOString().slice(0, 10) : null;

        if (lastUpdated === today) {
          newTodayCount = data.todayCount + 1;
          newTotalCount = data.totalCount + 1;
        } else {
          newTotalCount = data.totalCount + 1;
        }

        await updateDoc(visitorDocRef, {
          todayCount: newTodayCount,
          totalCount: newTotalCount,
          lastUpdated: serverTimestamp(),
        });
      } else {
        await setDoc(visitorDocRef, {
          todayCount: newTodayCount,
          totalCount: newTotalCount,
          lastUpdated: serverTimestamp(),
        });
      }

      return { todayCount: newTodayCount, totalCount: newTotalCount };
    } else {
      const visitorDoc = await getDoc(visitorDocRef);
      if (visitorDoc.exists()) {
        return { todayCount: visitorDoc.data().todayCount, totalCount: visitorDoc.data().totalCount };
      }
      return { todayCount: 0, totalCount: 0 };
    }
  } catch (error) {
    console.error('Error updating visitor count:', error);
    return { todayCount: 0, totalCount: 0 };
  }
};
