import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const ResponsiveContainer = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Box
      sx={{
        padding: isMobile ? '0 16px' : isTablet ? '0 32px' : '0 64px',
        margin: '0 auto',
        width: '100%',
        // maxWidth: '1500px',
      }}
    >
      {children}
    </Box>
  );
};

export default ResponsiveContainer;
