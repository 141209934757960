

// import React, { useState, useEffect } from 'react';
// import { Box, Button, Typography, TextField, Card, CardContent, CardActions, Snackbar, Alert, Avatar, IconButton, Modal } from '@mui/material';
// import { db, collection, addDoc, getDocs, doc, updateDoc } from '../firebaseConfig';
// import { formatDistanceToNow } from 'date-fns';
// import SendIcon from '@mui/icons-material/Send';
// import ReplyIcon from '@mui/icons-material/Reply';

// const Board = () => {
//   const [posts, setPosts] = useState([]);
//   const [postContent, setPostContent] = useState('');
//   const [replyContent, setReplyContent] = useState('');
//   const [replyingTo, setReplyingTo] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');

//   // Fetch posts from Firestore
//   const fetchPosts = async () => {
//     const querySnapshot = await getDocs(collection(db, 'boards'));
//     const postsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), createdAt: doc.data().createdAt.toDate() }));
//     setPosts(postsData);
//   };

//   // Handle new post submission
//   const handlePost = async () => {
//     if (postContent.trim() === '') return;

//     const newPost = {
//       content: postContent,
//       replies: [],
//       createdAt: new Date(),
//       user: 'Anonymous', // Add user info here if available
//     };

//     try {
//       const docRef = await addDoc(collection(db, 'boards'), newPost);
//       setPosts([...posts, { id: docRef.id, ...newPost }]);
//       setPostContent('');
//       setSnackbarMessage('Post added successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error adding post: ', error);
//       setSnackbarMessage('Error adding post');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   // Handle reply submission
//   const handleReply = async () => {
//     if (replyContent.trim() === '' || replyingTo === null) return;

//     const post = posts[replyingTo];
//     const updatedReplies = [...post.replies, { content: replyContent, createdAt: new Date(), user: 'Anonymous' }]; // Add user info here if available

//     try {
//       const postRef = doc(db, 'boards', post.id);
//       await updateDoc(postRef, { replies: updatedReplies });

//       const updatedPosts = [...posts];
//       updatedPosts[replyingTo].replies = updatedReplies;
//       setPosts(updatedPosts);
//       setReplyContent('');
//       setReplyingTo(null);
//       setSnackbarMessage('Reply added successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error adding reply: ', error);
//       setSnackbarMessage('Error adding reply');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   useEffect(() => {
//     fetchPosts();
//   }, []);

//   const handleReplyOpen = (index) => {
//     setReplyingTo(index);
//   };

//   const handleReplyClose = () => {
//     setReplyingTo(null);
//     setReplyContent('');
//   };

//   return (
//     <Box textAlign="center" p={3}>
//       <Typography variant="h4" gutterBottom>베타오픈 사용자 게시판</Typography>
//       <Box mb={3}>
//         <TextField 
//           variant="outlined"
//           fullWidth
//           multiline
//           minRows={3}
//           value={postContent}
//           onChange={(e) => setPostContent(e.target.value)}
//           placeholder="게시글을 작성하세요"
//           sx={{ mb: 2 }}
//         />
//         <Button variant="contained" color="primary" onClick={handlePost} endIcon={<SendIcon />} sx={{ mb: 2 }}>
//           게시
//         </Button>
//       </Box>
//       <Box>
//         {posts.length === 0 ? (
//           <Typography variant="body1">게시물이 없습니다.</Typography>
//         ) : (
//           posts.map((post, index) => (
//             <Card key={post.id} sx={{ marginBottom: '20px', textAlign: 'left' }}>
//               <CardContent>
//                 <Box display="flex" alignItems="center" mb={2}>
//                   <Avatar sx={{ mr: 2 }}>{post.user[0]}</Avatar>
//                   <Box>
//                     <Typography variant="body1">{post.content}</Typography>
//                     <Typography variant="caption" color="textSecondary">
//                       {formatDistanceToNow(new Date(post.createdAt))} ago
//                     </Typography>
//                   </Box>
//                 </Box>
//                 {post.replies.map((reply, replyIndex) => (
//                   <Box key={replyIndex} sx={{ mt: 2, pl: 4, borderLeft: '2px solid #ccc' }}>
//                     <Box display="flex" alignItems="center" mb={1}>
//                       <Avatar sx={{ mr: 2 }}>{reply.user[0]}</Avatar>
//                       <Box>
//                         <Typography variant="body2">{reply.content}</Typography>
//                         <Typography variant="caption" color="textSecondary">
//                           {formatDistanceToNow(new Date(reply.createdAt))} ago
//                         </Typography>
//                       </Box>
//                     </Box>
//                   </Box>
//                 ))}
//               </CardContent>
//               <CardActions>
//                 <Button variant="outlined" color="primary" onClick={() => handleReplyOpen(index)} startIcon={<ReplyIcon />}>
//                   답글
//                 </Button>
//               </CardActions>
//             </Card>
//           ))
//         )}
//       </Box>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>

//       <Modal
//         open={replyingTo !== null}
//         onClose={handleReplyClose}
//         aria-labelledby="reply-modal-title"
//         aria-describedby="reply-modal-description"
//       >
//         <Box sx={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: 400,
//           bgcolor: 'background.paper',
//           border: '2px solid #000',
//           boxShadow: 24,
//           p: 4,
//         }}>
//           <Typography id="reply-modal-title" variant="h6" component="h2">
//             답글 작성
//           </Typography>
//           <TextField 
//             variant="outlined"
//             fullWidth
//             multiline
//             minRows={3}
//             value={replyContent}
//             onChange={(e) => setReplyContent(e.target.value)}
//             placeholder="답글을 작성하세요"
//             sx={{ mt: 2, mb: 2 }}
//           />
//           <Button variant="contained" color="primary" onClick={handleReply}>
//             답글 달기
//           </Button>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default Board;


// import React, { useState, useEffect } from 'react';
// import { Box, Button, Typography, TextField, Card, CardContent, CardActions, Snackbar, Alert, Avatar, IconButton, Modal } from '@mui/material';
// import { db, collection, addDoc, getDocs, doc, updateDoc, Timestamp } from '../firebaseConfig';
// import { formatDistanceToNow } from 'date-fns';
// import SendIcon from '@mui/icons-material/Send';
// import ReplyIcon from '@mui/icons-material/Reply';

// const Board = () => {
//   const [posts, setPosts] = useState([]);
//   const [postContent, setPostContent] = useState('');
//   const [replyContent, setReplyContent] = useState('');
//   const [replyingTo, setReplyingTo] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');

//   // Fetch posts from Firestore
//   const fetchPosts = async () => {
//     try {
//       const querySnapshot = await getDocs(collection(db, 'boards'));
//       const postsData = querySnapshot.docs.map((doc) => {
//         const data = doc.data();
//         return {
//           id: doc.id,
//           ...data,
//           createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
//         };
//       });
//       setPosts(postsData);
//     } catch (error) {
//       console.error('Error fetching posts: ', error);
//     }
//   };

//   // Handle new post submission
//   const handlePost = async () => {
//     if (postContent.trim() === '') return;

//     const newPost = {
//       content: postContent,
//       replies: [],
//       createdAt: Timestamp.now(),
//       user: 'Anonymous', // Add user info here if available
//     };

//     try {
//       const docRef = await addDoc(collection(db, 'boards'), newPost);
//       setPosts([...posts, { id: docRef.id, ...newPost, createdAt: new Date() }]);
//       setPostContent('');
//       setSnackbarMessage('Post added successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error adding post: ', error);
//       setSnackbarMessage('Error adding post');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   // Handle reply submission
//   const handleReply = async () => {
//     if (replyContent.trim() === '' || replyingTo === null) return;

//     const post = posts[replyingTo];
//     const updatedReplies = [...post.replies, { content: replyContent, createdAt: new Date(), user: 'Anonymous' }]; // Add user info here if available

//     try {
//       const postRef = doc(db, 'boards', post.id);
//       await updateDoc(postRef, { replies: updatedReplies });

//       const updatedPosts = [...posts];
//       updatedPosts[replyingTo].replies = updatedReplies;
//       setPosts(updatedPosts);
//       setReplyContent('');
//       setReplyingTo(null);
//       setSnackbarMessage('Reply added successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error adding reply: ', error);
//       setSnackbarMessage('Error adding reply');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   useEffect(() => {
//     fetchPosts();
//   }, []);

//   const handleReplyOpen = (index) => {
//     setReplyingTo(index);
//   };

//   const handleReplyClose = () => {
//     setReplyingTo(null);
//     setReplyContent('');
//   };

//   return (
//     <Box textAlign="center" p={3}>
//       <Typography variant="h4" gutterBottom>베타오픈 사용자 게시판</Typography>
//       <Box mb={3}>
//         <TextField 
//           variant="outlined"
//           fullWidth
//           multiline
//           minRows={3}
//           value={postContent}
//           onChange={(e) => setPostContent(e.target.value)}
//           placeholder="게시글을 작성하세요"
//           sx={{ mb: 2 }}
//         />
//         <Button variant="contained" color="primary" onClick={handlePost} endIcon={<SendIcon />} sx={{ mb: 2 }}>
//           게시
//         </Button>
//       </Box>
//       <Box>
//         {posts.length === 0 ? (
//           <Typography variant="body1">게시물이 없습니다.</Typography>
//         ) : (
//           posts.map((post, index) => (
//             <Card key={post.id} sx={{ marginBottom: '20px', textAlign: 'left' }}>
//               <CardContent>
//                 <Box display="flex" alignItems="center" mb={2}>
//                   <Avatar sx={{ mr: 2 }}>{post.user ? post.user[0] : 'A'}</Avatar>
//                   <Box>
//                     <Typography variant="body1">{post.content}</Typography>
//                     <Typography variant="caption" color="textSecondary">
//                       {post.createdAt instanceof Date ? formatDistanceToNow(post.createdAt) : ''}
//                     </Typography>
//                   </Box>
//                 </Box>
//                 {post.replies.map((reply, replyIndex) => (
//                   <Box key={replyIndex} sx={{ mt: 2, pl: 4, borderLeft: '2px solid #ccc' }}>
//                     <Box display="flex" alignItems="center" mb={1}>
//                       <Avatar sx={{ mr: 2 }}>{reply.user ? reply.user[0] : 'A'}</Avatar>
//                       <Box>
//                         <Typography variant="body2">{reply.content}</Typography>
//                         <Typography variant="caption" color="textSecondary">
//                           {reply.createdAt instanceof Date ? formatDistanceToNow(reply.createdAt) : ''}
//                         </Typography>
//                       </Box>
//                     </Box>
//                   </Box>
//                 ))}
//               </CardContent>
//               <CardActions>
//                 <Button variant="outlined" color="primary" onClick={() => handleReplyOpen(index)} startIcon={<ReplyIcon />}>
//                   답글
//                 </Button>
//               </CardActions>
//             </Card>
//           ))
//         )}
//       </Box>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>

//       <Modal
//         open={replyingTo !== null}
//         onClose={handleReplyClose}
//         aria-labelledby="reply-modal-title"
//         aria-describedby="reply-modal-description"
//       >
//         <Box sx={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: 400,
//           bgcolor: 'background.paper',
//           border: '2px solid #000',
//           boxShadow: 24,
//           p: 4,
//         }}>
//           <Typography id="reply-modal-title" variant="h6" component="h2">
//             답글 작성
//           </Typography>
//           <TextField 
//             variant="outlined"
//             fullWidth
//             multiline
//             minRows={3}
//             value={replyContent}
//             onChange={(e) => setReplyContent(e.target.value)}
//             placeholder="답글을 작성하세요"
//             sx={{ mt: 2, mb: 2 }}
//           />
//           <Button variant="contained" color="primary" onClick={handleReply}>
//             답글 달기
//           </Button>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default Board;

// import React, { useState, useEffect } from 'react';
// import { Box, Button, Typography, TextField, Card, CardContent, CardActions, Snackbar, Alert, Avatar, IconButton, Modal } from '@mui/material';
// import { db, collection, addDoc, getDocs, doc, updateDoc, Timestamp, auth } from '../firebaseConfig';
// import moment from 'moment';
// import SendIcon from '@mui/icons-material/Send';
// import ReplyIcon from '@mui/icons-material/Reply';
// import { onAuthStateChanged } from 'firebase/auth';
// import { v4 as uuidv4 } from 'uuid';

// const generatePseudonym = () => {
//   const pseudonyms = ['UserA', 'UserB', 'UserC', 'UserD', 'UserE'];
//   return pseudonyms[Math.floor(Math.random() * pseudonyms.length)] + '-' + uuidv4().slice(0, 4);
// };

// const Board = () => {
//   const [posts, setPosts] = useState([]);
//   const [postContent, setPostContent] = useState('');
//   const [replyContent, setReplyContent] = useState('');
//   const [replyingTo, setReplyingTo] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       setUser(currentUser);
//     });
//     return () => unsubscribe();
//   }, []);

//   const userPseudonym = localStorage.getItem('userPseudonym') || generatePseudonym();

//   useEffect(() => {
//     if (!localStorage.getItem('userPseudonym')) {
//       localStorage.setItem('userPseudonym', userPseudonym);
//     }
//   }, [userPseudonym]);

//   const fetchPosts = async () => {
//     try {
//       const querySnapshot = await getDocs(collection(db, 'boards'));
//       const postsData = querySnapshot.docs.map((doc) => {
//         const data = doc.data();
//         return {
//           id: doc.id,
//           ...data,
//           createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
//         };
//       });
//       setPosts(postsData);
//     } catch (error) {
//       console.error('Error fetching posts: ', error);
//     }
//   };

//   const handlePost = async () => {
//     if (postContent.trim() === '') return;

//     const newPost = {
//       content: postContent,
//       replies: [],
//       createdAt: Timestamp.now(),
//       user: user ? user.displayName : userPseudonym,
//     };

//     try {
//       const docRef = await addDoc(collection(db, 'boards'), newPost);
//       setPosts([...posts, { id: docRef.id, ...newPost, createdAt: new Date() }]);
//       setPostContent('');
//       setSnackbarMessage('Post added successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error adding post: ', error);
//       setSnackbarMessage('Error adding post');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   const handleReply = async () => {
//     if (replyContent.trim() === '' || replyingTo === null) return;

//     const post = posts[replyingTo];
//     const updatedReplies = [...post.replies, { content: replyContent, createdAt: new Date(), user: user ? user.displayName : userPseudonym }];

//     try {
//       const postRef = doc(db, 'boards', post.id);
//       await updateDoc(postRef, { replies: updatedReplies });

//       const updatedPosts = [...posts];
//       updatedPosts[replyingTo].replies = updatedReplies;
//       setPosts(updatedPosts);
//       setReplyContent('');
//       setReplyingTo(null);
//       setSnackbarMessage('Reply added successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error adding reply: ', error);
//       setSnackbarMessage('Error adding reply');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   useEffect(() => {
//     fetchPosts();
//   }, []);

//   const handleReplyOpen = (index) => {
//     setReplyingTo(index);
//   };

//   const handleReplyClose = () => {
//     setReplyingTo(null);
//     setReplyContent('');
//   };

//   return (
//     <Box textAlign="center" p={3}>
//       <Typography variant="h4" gutterBottom>베타오픈 사용자 게시판</Typography>
//       <Box mb={3}>
//         <TextField 
//           variant="outlined"
//           fullWidth
//           multiline
//           minRows={3}
//           value={postContent}
//           onChange={(e) => setPostContent(e.target.value)}
//           placeholder="게시글을 작성하세요"
//           sx={{ mb: 2 }}
//         />
//         <Button variant="contained" color="primary" onClick={handlePost} endIcon={<SendIcon />} sx={{ mb: 2 }}>
//           게시
//         </Button>
//       </Box>
//       <Box>
//         {posts.length === 0 ? (
//           <Typography variant="body1">게시물이 없습니다.</Typography>
//         ) : (
//           posts.map((post, index) => (
//             <Card key={post.id} sx={{ marginBottom: '20px', textAlign: 'left' }}>
//               <CardContent>
//                 <Box display="flex" alignItems="center" mb={2}>
//                   <Avatar sx={{ mr: 2, bgcolor: '#3f51b5', color: '#fff' }}>{post.user ? post.user[0] : 'A'}</Avatar>
//                   <Box>
//                     <Typography variant="body1">{post.content}</Typography>
//                     <Typography variant="caption" color="textSecondary">
//                       {moment(post.createdAt).fromNow()} by {post.user}
//                     </Typography>
//                   </Box>
//                 </Box>
//                 {post.replies.map((reply, replyIndex) => (
//                   <Box key={replyIndex} sx={{ mt: 2, pl: 4, borderLeft: '2px solid #ccc' }}>
//                     <Box display="flex" alignItems="center" mb={1}>
//                       <Avatar sx={{ mr: 2, bgcolor: '#f50057', color: '#fff' }}>{reply.user ? reply.user[0] : 'A'}</Avatar>
//                       <Box>
//                         <Typography variant="body2" color="textPrimary">
//                           {reply.content}
//                         </Typography>
//                         <Typography variant="caption" color="textSecondary">
//                           {moment(reply.createdAt).fromNow()} by {reply.user}
//                         </Typography>
//                       </Box>
//                     </Box>
//                   </Box>
//                 ))}
//               </CardContent>
//               <CardActions>
//                 <Button variant="outlined" color="primary" onClick={() => handleReplyOpen(index)} startIcon={<ReplyIcon />}>
//                   답글
//                 </Button>
//               </CardActions>
//             </Card>
//           ))
//         )}
//       </Box>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>

//       <Modal
//         open={replyingTo !== null}
//         onClose={handleReplyClose}
//         aria-labelledby="reply-modal-title"
//         aria-describedby="reply-modal-description"
//       >
//         <Box sx={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: 400,
//           bgcolor: 'background.paper',
//           border: '2px solid #000',
//           boxShadow: 24,
//           p: 4,
//         }}>
//           <Typography id="reply-modal-title" variant="h6" component="h2">
//             답글 작성
//           </Typography>
//           <TextField 
//             variant="outlined"
//             fullWidth
//             multiline
//             minRows={3}
//             value={replyContent}
//             onChange={(e) => setReplyContent(e.target.value)}
//             placeholder="답글을 작성하세요"
//             sx={{ mt: 2, mb: 2 }}
//           />
//           <Button variant="contained" color="primary" onClick={handleReply}>
//             답글 달기
//           </Button>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default Board;

// import React, { useState, useEffect } from 'react';
// import { Box, Button, Typography, TextField, Card, CardContent, CardActions, Snackbar, Alert, Avatar, IconButton, Modal } from '@mui/material';
// import { db, collection, addDoc, getDocs, doc, updateDoc, Timestamp, auth } from '../firebaseConfig';
// import moment from 'moment';
// import SendIcon from '@mui/icons-material/Send';
// import ReplyIcon from '@mui/icons-material/Reply';
// import { onAuthStateChanged } from 'firebase/auth';
// import { v4 as uuidv4 } from 'uuid';
// import { useTranslation } from 'react-i18next';

// const generatePseudonym = () => {
//   const pseudonyms = ['UserA', 'UserB', 'UserC', 'UserD', 'UserE'];
//   return pseudonyms[Math.floor(Math.random() * pseudonyms.length)] + '-' + uuidv4().slice(0, 4);
// };

// const Board = () => {
//   const { t } = useTranslation();
//   const [posts, setPosts] = useState([]);
//   const [postContent, setPostContent] = useState('');
//   const [replyContent, setReplyContent] = useState('');
//   const [replyingTo, setReplyingTo] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       setUser(currentUser);
//     });
//     return () => unsubscribe();
//   }, []);

//   const userPseudonym = localStorage.getItem('userPseudonym') || generatePseudonym();

//   useEffect(() => {
//     if (!localStorage.getItem('userPseudonym')) {
//       localStorage.setItem('userPseudonym', userPseudonym);
//     }
//   }, [userPseudonym]);

//   const fetchPosts = async () => {
//     try {
//       const querySnapshot = await getDocs(collection(db, 'boards'));
//       const postsData = querySnapshot.docs.map((doc) => {
//         const data = doc.data();
//         return {
//           id: doc.id,
//           ...data,
//           createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
//         };
//       });
//       setPosts(postsData);
//     } catch (error) {
//       console.error('Error fetching posts: ', error);
//     }
//   };

//   const handlePost = async () => {
//     if (postContent.trim() === '') return;

//     const newPost = {
//       content: postContent,
//       replies: [],
//       createdAt: Timestamp.now(),
//       user: user ? user.displayName : userPseudonym,
//     };

//     try {
//       const docRef = await addDoc(collection(db, 'boards'), newPost);
//       setPosts([...posts, { id: docRef.id, ...newPost, createdAt: new Date() }]);
//       setPostContent('');
//       setSnackbarMessage(t('board.postAdded'));
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error adding post: ', error);
//       setSnackbarMessage(t('board.errorAddingPost'));
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   const handleReply = async () => {
//     if (replyContent.trim() === '' || replyingTo === null) return;

//     const post = posts[replyingTo];
//     const updatedReplies = [...post.replies, { content: replyContent, createdAt: new Date(), user: user ? user.displayName : userPseudonym }];

//     try {
//       const postRef = doc(db, 'boards', post.id);
//       await updateDoc(postRef, { replies: updatedReplies });

//       const updatedPosts = [...posts];
//       updatedPosts[replyingTo].replies = updatedReplies;
//       setPosts(updatedPosts);
//       setReplyContent('');
//       setReplyingTo(null);
//       setSnackbarMessage(t('board.replyAdded'));
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error('Error adding reply: ', error);
//       setSnackbarMessage(t('board.errorAddingReply'));
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   useEffect(() => {
//     fetchPosts();
//   }, []);

//   const handleReplyOpen = (index) => {
//     setReplyingTo(index);
//   };

//   const handleReplyClose = () => {
//     setReplyingTo(null);
//     setReplyContent('');
//   };

//   return (
//     <Box  id="board" textAlign="center" p={3}>
//       <Typography variant="h4" gutterBottom>{t('board.title')}</Typography>
//       <Box mb={3}>
//         <TextField 
//           variant="outlined"
//           fullWidth
//           multiline
//           minRows={3}
//           value={postContent}
//           onChange={(e) => setPostContent(e.target.value)}
//           placeholder={t('board.placeholder')}
//           sx={{ mb: 2 }}
//         />
//         <Button variant="contained" color="primary" onClick={handlePost} endIcon={<SendIcon />} sx={{ mb: 2 }}>
//           {t('board.postButton')}
//         </Button>
//       </Box>
//       <Box>
//         {posts.length === 0 ? (
//           <Typography variant="body1">{t('board.noPosts')}</Typography>
//         ) : (
//           posts.map((post, index) => (
//             <Card key={post.id} sx={{ marginBottom: '20px', textAlign: 'left' }}>
//               <CardContent>
//                 <Box display="flex" alignItems="center" mb={2}>
//                   <Avatar sx={{ mr: 2, bgcolor: '#3f51b5', color: '#fff' }}>{post.user ? post.user[0] : 'A'}</Avatar>
//                   <Box>
//                     <Typography variant="body1">{post.content}</Typography>
//                     <Typography variant="caption" color="textSecondary">
//                       {moment(post.createdAt).fromNow()} {t('board.by')} {post.user}
//                     </Typography>
//                   </Box>
//                 </Box>
//                 {post.replies.map((reply, replyIndex) => (
//                   <Box key={replyIndex} sx={{ mt: 2, pl: 4, borderLeft: '2px solid #ccc' }}>
//                     <Box display="flex" alignItems="center" mb={1}>
//                       <Avatar sx={{ mr: 2, bgcolor: '#f50057', color: '#fff' }}>{reply.user ? reply.user[0] : 'A'}</Avatar>
//                       <Box>
//                         <Typography variant="body2" color="textPrimary">
//                           {reply.content}
//                         </Typography>
//                         <Typography variant="caption" color="textSecondary">
//                           {moment(reply.createdAt).fromNow()} {t('board.by')} {reply.user}
//                         </Typography>
//                       </Box>
//                     </Box>
//                   </Box>
//                 ))}
//               </CardContent>
//               <CardActions>
//                 <Button variant="outlined" color="primary" onClick={() => handleReplyOpen(index)} startIcon={<ReplyIcon />}>
//                   {t('board.replyButton')}
//                 </Button>
//               </CardActions>
//             </Card>
//           ))
//         )}
//       </Box>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>

//       <Modal
//         open={replyingTo !== null}
//         onClose={handleReplyClose}
//         aria-labelledby="reply-modal-title"
//         aria-describedby="reply-modal-description"
//       >
//         <Box sx={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: 400,
//           bgcolor: 'background.paper',
//           border: '2px solid #000',
//           boxShadow: 24,
//           p: 4,
//         }}>
//           <Typography id="reply-modal-title" variant="h6" component="h2">
//             {t('board.replyModalTitle')}
//           </Typography>
//           <TextField 
//             variant="outlined"
//             fullWidth
//             multiline
//             minRows={3}
//             value={replyContent}
//             onChange={(e) => setReplyContent(e.target.value)}
//             placeholder={t('board.replyPlaceholder')}
//             sx={{ mt: 2, mb: 2 }}
//           />
//           <Button variant="contained" color="primary" onClick={handleReply}>
//             {t('board.replySubmit')}
//           </Button>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default Board;



import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography, TextField, Card, CardContent, CardActions, Snackbar, Alert, Avatar, Modal, CircularProgress } from '@mui/material';
import { db, collection, addDoc, getDocs, doc, updateDoc, Timestamp, auth } from '../firebaseConfig';
import moment from 'moment';
import SendIcon from '@mui/icons-material/Send';
import ReplyIcon from '@mui/icons-material/Reply';
import { onAuthStateChanged } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

const generatePseudonym = () => {
  const pseudonyms = ['UserA', 'UserB', 'UserC', 'UserD', 'UserE'];
  return pseudonyms[Math.floor(Math.random() * pseudonyms.length)] + '-' + uuidv4().slice(0, 4);
};

const Board = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [replyContent, setReplyContent] = useState('');
  const [replyingTo, setReplyingTo] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const userPseudonym = useCallback(() => {
    const storedPseudonym = localStorage.getItem('userPseudonym');
    if (storedPseudonym) return storedPseudonym;
    const newPseudonym = generatePseudonym();
    localStorage.setItem('userPseudonym', newPseudonym);
    return newPseudonym;
  }, []);

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'boards'));
      const postsData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
        };
      });
      setPosts(postsData);
    } catch (error) {
      console.error('Error fetching posts: ', error);
      setSnackbarMessage(t('board.errorFetchingPosts'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePost = async () => {
    if (postContent.trim() === '') return;

    const newPost = {
      content: postContent,
      replies: [],
      createdAt: Timestamp.now(),
      user: user ? user.displayName : userPseudonym(),
    };

    try {
      const docRef = await addDoc(collection(db, 'boards'), newPost);
      setPosts([...posts, { id: docRef.id, ...newPost, createdAt: new Date() }]);
      setPostContent('');
      setSnackbarMessage(t('board.postAdded'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error adding post: ', error);
      setSnackbarMessage(t('board.errorAddingPost'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleReply = async () => {
    if (replyContent.trim() === '' || replyingTo === null) return;

    const post = posts[replyingTo];
    const updatedReplies = [...post.replies, { content: replyContent, createdAt: new Date(), user: user ? user.displayName : userPseudonym() }];

    try {
      const postRef = doc(db, 'boards', post.id);
      await updateDoc(postRef, { replies: updatedReplies });

      const updatedPosts = [...posts];
      updatedPosts[replyingTo].replies = updatedReplies;
      setPosts(updatedPosts);
      setReplyContent('');
      setReplyingTo(null);
      setSnackbarMessage(t('board.replyAdded'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error adding reply: ', error);
      setSnackbarMessage(t('board.errorAddingReply'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleReplyOpen = (index) => {
    setReplyingTo(index);
  };

  const handleReplyClose = () => {
    setReplyingTo(null);
    setReplyContent('');
  };

  return (
    <Box id="board" textAlign="center" p={3}>
      <Typography variant="h4" gutterBottom>{t('board.title')}</Typography>
      <Box mb={3}>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
          placeholder={t('board.placeholder')}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handlePost} endIcon={<SendIcon />} sx={{ mb: 2 }}>
          {t('board.postButton')}
        </Button>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {posts.length === 0 ? (
            <Typography variant="body1">{t('board.noPosts')}</Typography>
          ) : (
            posts.map((post, index) => (
              <Card key={post.id} sx={{ marginBottom: '20px', textAlign: 'left' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar sx={{ mr: 2, bgcolor: '#3f51b5', color: '#fff' }}>{post.user ? post.user[0] : 'A'}</Avatar>
                    <Box>
                      <Typography variant="body1">{post.content}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {moment(post.createdAt).fromNow()} {t('board.by')} {post.user}
                      </Typography>
                    </Box>
                  </Box>
                  {post.replies.map((reply, replyIndex) => (
                    <Box key={replyIndex} sx={{ mt: 2, pl: 4, borderLeft: '2px solid #ccc' }}>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Avatar sx={{ mr: 2, bgcolor: '#f50057', color: '#fff' }}>{reply.user ? reply.user[0] : 'A'}</Avatar>
                        <Box>
                          <Typography variant="body2" color="textPrimary">
                            {reply.content}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {moment(reply.createdAt).fromNow()} {t('board.by')} {reply.user}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button variant="outlined" color="primary" onClick={() => handleReplyOpen(index)} startIcon={<ReplyIcon />}>
                    {t('board.replyButton')}
                  </Button>
                </CardActions>
              </Card>
            ))
          )}
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Modal
        open={replyingTo !== null}
        onClose={handleReplyClose}
        aria-labelledby="reply-modal-title"
        aria-describedby="reply-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="reply-modal-title" variant="h6" component="h2">
            {t('board.replyModalTitle')}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            value={replyContent}
            onChange={(e) => setReplyContent(e.target.value)}
            placeholder={t('board.replyPlaceholder')}
            sx={{ mt: 2, mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleReply}>
            {t('board.replySubmit')}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Board;
